import { createTheme, createDarkTheme } from 'baseui';
import Theme from 'theme';

const fontsPrimitives = {
  ...Theme,
  primaryFontFamily: 'Lexend',
  seondaryFontFamily: 'Roboto',
};

const modalBackDropFilter = 'blur(2px)';
const leftNavBackgroundColor = 'linear-gradient(180deg, #0D0E13 0%, rgba(13, 14, 19, 0.9) 100%)';

const imageSizes = {
  home_component_slider: 'full_0.5_x_url',
  home_component_single_image: 'full_0.5_x_url',
  home_component_header_image: 'full_0.5_x_url',
  home_component_single_video_cover: 'full_0.5_x_url',
  channel_logo: 'full_url',
  channel_icon: 'full_url',
  deck_cover: 'cover_small_url',
  card_font_carousel_cover: 'cover_medium_url',
  card_font_grid_cover: 'cover_small_url',
  card_back_single_image: 'full_url',
  card_back_gallery_full_preview: 'full_url',
  card_back_gallery_thumbnail: 'thumbnail_small_url',
  card_back_gallery_image: 'full_url',
  single_audio_cover_photo: 'thumbnail_large_url',
  audio_video_list_image: 'thumbnail_large_url',
  audio_player_cover: 'full_url',
  video_player_cover: 'full_url',

  card_back_header_image: 'full_url',
  card_back_single_video_cover: 'full_url',
  search_page_card_cover: 'cover_small_url',
  fallback_size: 'full_url',

  sing_up_modal_header_logo: 'full_0.5_x_url',
  article_list_main_image: 'full_0.5_x_url'
};

const lightThemeOverride = {
  leftNavBackgroundColor,
  modalBackDropFilter,
  leftNavBar: {
    selectedTextColor: '#EF4778',
    unSelectedTextColor: '#FFFFFFE5'
  },
  colors: {
    primaryC: '#7A5AF8', // PURPLE COLOR - #765FED
    channelCardBackgroundColor: '#EAEAEA',
    builderNavigationBackground: '#FBFBFB',
    channelManagerBackgroundColor: '#ECEBEB'
  },
  lighting: {
    leftNavBar: {
      selectedTextColor: '#EF4778',
      unSelectedTextColor: '#FFFFFFE5'
    },
    builderNavigationShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    shadowCardware: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
};

const darkThemeOverride = {
  leftNavBackgroundColor,
  modalBackDropFilter,
  leftNavBar: {
    selectedTextColor: '#EF4778',
    unSelectedTextColor: '#FFFFFFE5'
  },
  colors: {
    primary50: '#1E1F2C', // Black COLOR - #131313
    primaryC: '#7A5AF8',
    channelCardBackgroundColor: '#EAEAEA',
    builderNavigationBackground: '#FBFBFB',
    channelManagerBackgroundColor: 'linear-gradient(rgb(13, 14, 19) 0%, rgba(13, 14, 19, 0.9) 100%)'
  },
  lighting: {
    leftNavBar: {
      selectedTextColor: '#EF4778',
      unSelectedTextColor: '#FFFFFFE5'
    },
    builderNavigationShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    shadowCardware: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
};

var overrides = {
  grid: {
    maxWidth: 'unset',
  },
};
export const darkTheme = createDarkTheme(fontsPrimitives, {
  ...darkThemeOverride,
  ...overrides,
  imageSizes
});
export const lightTheme = createTheme(fontsPrimitives, {
  ...lightThemeOverride,
  ...overrides,
  imageSizes
});